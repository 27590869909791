import { UserType } from "@root/types/user"

const rolesMap: {
  [key: string]: UserType[]
} = {
  ["admin"]: ["admin"],
  ["chief"]: ["admin", "chief"],
  ["manager"]: ["admin", "chief", "manager"],
  ["training"]: ["admin", "chief", "manager", "training"],
  ["brand"]: ["admin", "brand", "chief", "manager", "training"],
}

const expandUserRoles = (userType: UserType) => rolesMap[userType]

export const isRoleMatching = (
  role: UserType,
  userType: UserType,
  strict: boolean
) => {
  if (strict) {
    return userType === role || userType === "admin"
  }
  const userTypes = expandUserRoles(role)
  return userType && userTypes.includes(userType)
}

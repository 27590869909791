import { useAuthGuard } from "@modules/auth/hooks"
import React from "react"
import { RootContentsProvider, RootData } from "./data/context"
import { UserType } from "./types/user"

interface Props {
  children: any
  role?: UserType
  publicPage?: boolean
  rootData: RootData
}

export const WrapPage = ({ children, role, publicPage, rootData }: Props) => {
  useAuthGuard({ role, public: publicPage })

  return (
    <RootContentsProvider value={rootData}>{children}</RootContentsProvider>
  )
}

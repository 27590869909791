import React from "react"
import { IconsMap } from "../../contents/icons/types"
import { LabelsMap } from "../../contents/labels/types"

export interface RootData {
  labelsData: LabelsMap | undefined
  iconsData: IconsMap | undefined
}

export const RootContentsContext = React.createContext<RootData>({
  labelsData: undefined,
  iconsData: undefined,
})

export const RootContentsProvider = RootContentsContext.Provider

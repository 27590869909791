import { PageSEO } from "@modules/seo"
import { usePostLoginRedirect } from "@routing/guard"
import { WrapPage } from "../root"
import { RootData } from "../root/data/context"
import { wrapPage } from "../root/page"

interface Props {
  rootData: RootData
}

const Home = ({ rootData }: Props) => {
  usePostLoginRedirect()
  return (
    <WrapPage rootData={rootData}>
      <PageSEO pageTranslationsKey="common" siteTranslationsKey="common" />
    </WrapPage>
  )
}

export default Home

export async function getStaticProps({ locale }: any) {
  return await wrapPage({
    locale,
  })
}

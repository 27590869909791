import { absoluteUrl, localizePath } from "@routing/urls"
import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"
import { OpenGraphSEO } from "./OpenGraphSEO"

interface Props {
  title: string
  description: string
  icon: string
  cover: string
  siteName: string
  url?: string
  noIndex?: boolean
  noFollow?: boolean
}

const buildRobots = (input: { noIndex?: boolean; noFollow?: boolean }) => {
  return [
    input.noIndex ? "noindex" : "index",
    input.noFollow ? "nofollow" : "follow",
  ].join(", ")
}

export const PageMetadata = ({
  title,
  description,
  icon,
  cover,
  siteName,
  url,
  noIndex,
  noFollow,
}: Props) => {
  const router = useRouter()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="robots"
          content={buildRobots({
            noFollow,
            noIndex,
          })}
        />
        <link rel="canonical" href={absoluteUrl(router.asPath)} />
        <link rel="alternate" href={absoluteUrl(router.asPath)} hrefLang="it" />
        <link
          rel="alternate"
          href={absoluteUrl(localizePath(router.asPath, "en"))}
          hrefLang="en"
        />
        <link
          rel="alternate"
          href={absoluteUrl(localizePath(router.asPath, "en"))}
          hrefLang="x-default"
        />

        <link rel="icon" href={icon} />
      </Head>
      <OpenGraphSEO
        description={description}
        title={title}
        type=""
        cover={cover}
        siteName={siteName}
        url={url}
      />
    </>
  )
}

import { useTranslations } from "@modules/translations"
import { absoluteUrl } from "@routing/urls"
import React from "react"
import { PageMetadata } from "../PageMetadata"

interface Props {
  siteTranslationsKey: string
  pageTranslationsKey: string
  pagePath?: string
  noIndex?: boolean
  noFollow?: boolean
}

export const PageSEO = ({
  siteTranslationsKey,
  pageTranslationsKey,
  pagePath,
  noIndex,
  noFollow,
}: Props) => {
  const { t: siteT } = useTranslations(siteTranslationsKey)
  const { t: pageT } = useTranslations(pageTranslationsKey)

  return (
    <>
      <PageMetadata
        title={[pageT("seo.title"), siteT("seo.site")]
          .filter((x) => x)
          .join(" | ")}
        description={pageT("seo.description")}
        icon={siteT("site.favicon")}
        cover={siteT("site.cover")}
        siteName={siteT("seo.site")}
        url={pagePath ? absoluteUrl(pagePath) : undefined}
        noFollow={noFollow}
        noIndex={noIndex}
      />
    </>
  )
}

PageSEO.defaultProps = {
  siteTranslationsKey: "common",
}

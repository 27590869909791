import { UserType } from "@root/types/user"
import { useAppSelector } from "@modules/store/redux"
import { useRouter } from "next/router"
import { loginPagePath, unauthorizedPagePath } from "@routing/paths"
import { isRoleMatching } from "../roles"

export const useAuthGuard = ({
  role,
  public: publicPage,
}: {
  role?: UserType
  public?: boolean
}) => {
  const router = useRouter()
  const { authenticated, userType, isFirstCheckCompleted } = useAppSelector(
    (state) => state.auth
  )

  if (publicPage) {
    return
  }

  if (!isFirstCheckCompleted) {
    return
  }

  if (!authenticated) {
    router.push(loginPagePath())
    return
  }

  if (!role) {
    return
  }

  if (!userType || !isRoleMatching(role, userType, false)) {
    router.push(unauthorizedPagePath())
  }
}

import { useAppSelector } from "@modules/store/redux"
import { useRouter } from "next/router"
import {
  coursesPagePath,
  dashboardPagePath,
  unauthorizedPagePath,
} from "./paths"

export const usePostLoginRedirect = () => {
  const router = useRouter()
  const { userType, isFirstCheckCompleted, authenticated } = useAppSelector(
    (state) => state.auth
  )

  if (!isFirstCheckCompleted || !authenticated) {
    return
  }

  if (userType === "brand") {
    router.push(coursesPagePath())
    return
  }

  if (
    userType === "admin" ||
    userType === "manager" ||
    userType === "training"
  ) {
    router.push(dashboardPagePath())
    return
  }

  router.push(unauthorizedPagePath())
}

import Head from "next/head"
import React from "react"

interface Props {
  title: string
  type: string
  description: string
  url?: string
  siteName?: string
  image?: string
  cover?: string
}
export const OpenGraphSEO = ({
  title,
  type,
  description,
  url,
  siteName,
  image,
  cover,
}: Props) => {
  return (
    <Head>
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      {url && <meta property="og:url" content={url} />}
      {siteName && <meta property="og:site_name" content={siteName} />}
      <meta property="og:image" content={image ?? cover} />
    </Head>
  )
}
